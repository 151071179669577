<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" class="login-form" label-position="left">
      <div class="title-container">
        <h3 class="title">校园二手交易系统</h3>
      </div>
      <div class="top_area">
        <el-checkbox v-model="kaptcha_select">是否需要验证码</el-checkbox>
        <div class="register_text">
          <span>没有账号？<a class="go-to-register" href="/register">点击注册</a></span>
        </div>
      </div>
      <el-form-item prop="username">
        <span class="svg-container">
          <i class="el-icon-user"></i>
        </span>
        <el-input ref="username" v-model="loginForm.user" placeholder="用户名" name="user" type="text" />
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock"></i>
        </span>
        <el-input ref="password" v-model="loginForm.password" placeholder="密码" name="password" type="password" show-password />
      </el-form-item>
      <el-form-item v-if="kaptcha_select">
        <div class="kaptcha">
          <el-input v-model="kaptcha_code" placeholder="请输入验证码" type="text"></el-input>
          <el-image :src="kaptcha_url" @click="get_kaptcha"> </el-image>
        </div>
      </el-form-item>
      <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="login">登录</el-button>
      <span class="test_account">需要测试账号请联系qq群管理员</span>
    </el-form>
    <div>
      <span class="beian">Copyright © 2023 tcpjwtester.top All Rights Reserved . <a href="https://beian.miit.gov.cn/" target="_blank" style="color: white">苏ICP备2021042560号-1</a></span>
    </div>
  </div>
</template>

<script>
import { setToken } from '@/utils/auth'

export default {
  name: 'Login',
  data() {
    return {
      kaptcha_select: false,
      kaptcha_url: '',
      kaptcha_code: '',
      loginForm: {
        user: '',
        password: ''
      },
      loading: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.get_kaptcha()
  },
  mounted() {
    if (this.loginForm.user === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {
    get_kaptcha() {
      this.$api.user.getKaptcha().then(res => {
        console.log(res)
        this.kaptcha_url = `data: image/jpeg;base64,${btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
      })
    },
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$api.user.login(this.loginForm).then(res => {
            let _this = this
            if (res.data.code === 200) {
              _this.$message.success({
                message: '登录成功',
                center: true
              })
              const token = res.data.data.token
              setToken(token)
              _this.$router.push({ path: '/' })
            } else {
              _this.$router.push('/login')
              _this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000,
                showClose: true
              })
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.beian{
  display: flex;
  justify-content: center;
  color: #8c939d;
  margin-top: 30px;
}
.kaptcha {
  display: flex;
  flex-direction: row;
}
.top_area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register_text {
  color: #8c939d;
  display: flex;
  justify-content: right;
}
.test_account {
  color: #8c939d;
  display: flex;
  justify-content: left;
}
.go-to-register {
  color: #33aef0;
}
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
